import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorDialogModule } from '@portal-core/general/components/error-dialog/error-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    ErrorDialogModule
  ]
})
export class GeneralServicesModule { }
