import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EmptySiteKey } from '@portal-core/sites/constants/empty-site-key.constant';
import { AlertType } from '@portal-core/general/enums/alert-type.enum';

@Injectable({
  providedIn: 'root'
})
export class PageNotFoundGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const siteKey = next.params.siteKey || EmptySiteKey;

    this.router.navigate(['/', siteKey, 'alert'], { queryParams: { type: AlertType.PageNotFound } });

    return false;
  }
}
