import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { TaskAssetsState } from '@portal-core/tasks/services/task-assets-data.service';
import { TaskCommentsState } from '@portal-core/tasks/services/task-comments-data.service';
import { TasksState } from '@portal-core/tasks/services/tasks-data.service';

@NgModule({
  imports: [
    NgxsModule.forFeature([TasksState, TaskCommentsState, TaskAssetsState])
  ],
})
export class TasksServicesModule { }
