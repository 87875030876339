import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { CoreService } from '@portal-output/core/services/core.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteGuardService implements CanActivate {
  constructor(private coreService: CoreService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const siteKey = route.params.siteKey;

    return this.coreService.setSiteKey$(siteKey).pipe(
      map(() => true)
    );
  }
}
