import { Injectable } from '@angular/core';
import { CollectionActionsService } from '@portal-core/data/collection/services/collection-actions.service';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsState } from '@portal-core/projects/services/projects-data.service';

/**
 * Hydrates project models as they are added to the projects data store.
 * Listens for projects being added to the Projects data store and updates each project's properties before they are inserted into the store.
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectHydratorEffect {
  constructor(private collectionActionsService: CollectionActionsService) {
    this.collectionActionsService.updatingItems$<Project>(ProjectsState.source).subscribe(actionItems => this.hydrateProjects(actionItems.new));
  }

  private hydrateProjects(projects: Project[]) {
    if (Array.isArray(projects)) {
      projects.forEach(project => {
        // The server can return the UserHasAccess property even if it doesn't set the value. If this is the case then delete the property so that it does not override the current value in the data store.
        if ('UserHasAccess' in project && typeof project.UserHasAccess !== 'boolean') {
          delete project.UserHasAccess;
        }

        // The server can return null for ProjectTeams when it doesn't load the teams. If this is the case then delete the property so it does not override the current value in the data store.
        if (project.ProjectTeams === null) {
          delete project.ProjectTeams;
        }
        // The server can return null for ProjectUsers when it doesn't load the users. If this is the case then delete the property so it does not override the current value in the data store.
        if (project.ProjectUsers === null) {
          delete project.ProjectUsers;
        }
      });
    }
  }
}
