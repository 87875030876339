import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ProjectTargetsState } from './project-targets-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([ProjectTargetsState])
  ]
})
export class ProjectTargetsServicesModule { }
