import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { ProjectChecklistItem } from '@portal-core/project-checklists/models/project-checklist-item.model';

@CollectionStateSelectors({
  stateType: ProjectChecklistItemsState
})
@State<ProjectChecklistItemsState>({
  name: ProjectChecklistItemsState.source
})
@Injectable()
export class ProjectChecklistItemsState extends CollectionStateBase {
  static source = 'ProjectChecklistItems';
  getSource(): string {
    return ProjectChecklistItemsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectChecklistItemsDataService extends CollectionDataServiceBase<ProjectChecklistItem> {
  constructor(protected store: Store) {
    super(store, ProjectChecklistItemsState);
  }
}
