import { forEach } from 'lodash';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';

export function addIdAddTypeToPageFilterGroup(state: any, key: string): any {
  // If this is a paged filter config state
  if (key.endsWith('.config')) {
    // If the config has a filters object
    if (state?.filters) {
      // Loop through each PageFilterGroup in the filters object
      forEach(state?.filters, (filterGroup: PageFilterGroup, name) => {
        // If the PageFilterGroup doesn't have an Id then it is an older unsupported version
        if (!filterGroup.Id) {
          // Remove the PageFilterGroup from the filters
          delete state.filters[name];
        }
      });
    }
  }

  // Return the new state
  return state;
}
