import { Injectable } from '@angular/core';
import { Actions, ActionType, ofActionDispatched, ofActionSuccessful } from '@ngxs/store';
import { ActionContext } from '@ngxs/store/src/actions-stream';
import { AddDataListPage, AddGridPage, AddItems, DeleteItems } from '@portal-core/data/collection/types/collection-state.type';
import { ModelIds } from '@portal-core/data/common/types/mc-model.type';
import { filter, map, merge, Observable, OperatorFunction } from 'rxjs';

type OfActionFunction = (...allowedTypes: ActionType[]) => OperatorFunction<ActionContext, any>;

export interface ActionItems<T> {
  new: T[];
  old: Dictionary<T>;
}

/**
 * CollectionActionsService
 * Provides observable actions for collection data stores.
 */
@Injectable({
  providedIn: 'root'
})
export class CollectionActionsService {
  constructor(private actions$: Actions) { }

  /**
   * Returns an observable that emits whenever items are going to be added or updated in the data store.
   * The emitted value is an object with the new items as an array and the original version of those items as a dictionary.
   * @param source the source name of the data store to observe
   */
  updatingItems$<T>(source: string): Observable<ActionItems<T>> {
    return this.itemsFromAction$<T>(source, ofActionDispatched);
  }

  /**
   * Returns an observable that emits whenever items are going to be deleted from the data store.
   * The emitted value is an array of numbers that represents the IDs of the items to be deleted.
   * @param source the source name of the data store to observe
   */
  deleteItems$(source: string): Observable<ModelIds> {
    return this.itemsToDelete$(source, ofActionDispatched);
  }

  /**
   * Returns an observable that emits whenever an item is added or updated in the data store.
   * The emitted value is an object with the new items as an array and the original version of those items as a dictionary.
   * @param source the source name of the data store to observe
   */
  updatedItems$<T>(source: string): Observable<ActionItems<T>> {
    return this.itemsFromAction$<T>(source, ofActionSuccessful);
  }

  /**
   * Observes the different actions that add/update items in the data store and filters them by a particular action.
   * @param source the source name of the data store to observe
   * @param ofAction the rxjs operator to select the action type that is observed
   */
  private itemsFromAction$<T>(source: string, ofAction: OfActionFunction): Observable<ActionItems<T>> {
    return merge(
      this.actions$.pipe(
        ofAction(AddItems),
        filter((action: AddItems) => action.source === source),
        map((action: AddItems) => {
          return {
            new: action.payload.items ? Object.values(action.payload.items) : [],
            old: action.oldItems
          };
        })
      ),
      this.actions$.pipe(
        ofAction(AddGridPage),
        filter((action: AddGridPage) => action.source === source),
        map((action: AddGridPage) => {
          return {
            new: action.payload.page.Items,
            old: action.oldItems
          };
        })
      ),
      this.actions$.pipe(
        ofAction(AddDataListPage),
        filter((action: AddDataListPage) => action.source === source),
        map((action: AddDataListPage) => {
          return {
            new: action.payload.page.Items,
            old: action.oldItems
          };
        })
      )
    );
  }

  private itemsToDelete$(source: string, ofAction: OfActionFunction): Observable<ModelIds> {
    return merge(
      this.actions$.pipe(
        ofAction(DeleteItems),
        filter((action: DeleteItems) => action.source === source),
        map((action: DeleteItems) => action.payload.itemIds)
      )
    )
  }
}
