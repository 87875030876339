import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TeamsState } from './teams-data.service';
import { NgxsModule } from '@ngxs/store';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([TeamsState])
  ]
})
export class TeamsServicesModule { }
