import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { LicenseUsersState } from './license-users-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([LicenseUsersState])
  ]
})
export class LicenseUsersServicesModule { }
