import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CoreService } from '@portal-output/core/services/core.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReturnUrlResolverService implements Resolve<string> {
  constructor(private coreService: CoreService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const returnUrl: string = route.queryParams.returnUrl ?
      route.queryParams.returnUrl + (route.fragment ? '#' + route.fragment : '') : '';

    return this.coreService.setReturnUrl$(returnUrl).pipe(
      map(() => returnUrl)
    );
  }
}
