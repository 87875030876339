import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EmptyModule } from '@portal-core/general/components/empty/empty.module';
import { DialogsModule } from '@portal-core/dialogs/dialogs.module';
import { CoreModule } from '@portal-output/core/core.module';
import { AppComponent } from '@portal-output/app/components/app/app.component';
import { AppRoutingModule } from '@portal-output/app/app-routing.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    DialogsModule,
    EmptyModule,
    AppRoutingModule // Last Import (AppRoutingModule needs to be the last import in the imports array)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
