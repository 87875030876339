import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { AuthRouteService } from '@portal-core/auth/services/auth-route.service';
import { AuthService } from '@portal-core/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService, private authRouteService: AuthRouteService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      this.authRouteService.navigateToAuthRoute$(AuthRoute.Login).subscribe();
      return false;
    } else {
      return true;
    }
  }
}
