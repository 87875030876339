import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BuildsState } from './builds-data.service';

@NgModule({
  imports: [
    NgxsModule.forFeature([BuildsState])
  ]
})
export class BuildsServicesModule { }
