import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@portal-core/auth/guards/auth-guard.service';
import { LoginGuardService } from '@portal-core/auth/guards/login-guard.service';
import { SubdomainGuardService } from '@portal-core/auth/guards/subdomain-guard.service';
import { EmptyComponent } from '@portal-core/general/components/empty/empty.component';
import { AlertType } from '@portal-core/general/enums/alert-type.enum';
import { PageNotFoundGuard } from '@portal-output/app/services/page-not-found.guard';
import { LicenseResolverService } from '@portal-output/core/services/license-resolver.service';
import { ReturnUrlResolverService } from '@portal-output/core/services/return-url-resolver.service';
import { SiteGuardService } from '@portal-output/core/services/site-guard.service';

const routes: Routes = [
  /** This route is used when the subdomain is invalid. It is at the root because it does not require a valid subdomain. */
  {
    path: 'licenseNotFound',
    data: {
      type: AlertType.LicenseNotFound
    },
    loadChildren: () => import('../alert/alert.module').then(m => m.AlertModule),
  },
  /** The root route for the rest of the pages in the app. */
  {
    path: '',
    canActivate: [SubdomainGuardService],
    children: [
      {
        path: ':siteKey',
        canActivate: [SiteGuardService],
        resolve: {
          licenseId: LicenseResolverService,
          returnUrl: ReturnUrlResolverService
        },
        children: [
          {
            path: 'alert',
            loadChildren: () => import('../alert/alert.module').then(m => m.AlertModule)
          },
          {
            path: 'confirm',
            children: [
              {
                path: 'sso',
                loadChildren: () => import('../confirm/sso/sso.module').then(m => m.SsoModule)
              },
              {
                path: 'sso-email',
                loadChildren: () => import('../confirm/new-sso-user/new-sso-user.module').then(m => m.NewSsoUserModule)
              },
              {
                path: '',
                loadChildren: () => import('../complete-invite/complete-invite.module').then(m => m.CompleteInviteModule)
              }
            ]
          },
          {
            path: 'expired-password',
            loadChildren: () => import('../expired-password/expired-password.module').then(m => m.ExpiredPasswordModule)
          },
          {
            path: 'forgot-password',
            loadChildren: () => import('../forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
          },
          {
            path: 'login',
            loadChildren: () => import('../login/login.module').then(m => m.LoginModule)
          },
          {
            path: 'logout',
            loadChildren: () => import('../logout/logout.module').then(m => m.LogoutModule)
          },
          {
            path: 'reset-password',
            loadChildren: () => import('../reset-password/reset-password.module').then(m => m.ResetPasswordModule)
          },
          {
            path: '',
            canActivate: [LoginGuardService],
            children: [
              {
                path: '',
                canActivate: [AuthGuardService],
                children: [
                  {
                    path: 'sites',
                    loadChildren: () => import('../sites/sites.module').then(m => m.SitesModule)
                  },
                  {
                    path: 'user-profile',
                    loadChildren: () => import('../user-profile/user-profile.module').then(m => m.UserProfileModule)
                  }
                ]
              }
            ]
          },
          {
            path: '**',
            canActivate: [PageNotFoundGuard],
            component: EmptyComponent
          }
        ]
      }
    ]
  },
  {
    path: '**',
    canActivate: [PageNotFoundGuard],
    component: EmptyComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
